/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'blackholefinder',
    appId: '1:553589883639:web:0ec126f15d4bebb6da89f2',
    storageBucket: 'blackholefinder.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyBMwhUcic3aY2bpg3Rk4JrYCu-eWPst8aM',
    authDomain: 'blackholefinder.firebaseapp.com',
    messagingSenderId: '553589883639',
    measurementId: 'G-QQYNCK8Y3H',
  },
  
  dataspex:{
    url: 'https://bg-citsci-gateway-9okw58fz.nw.gateway.dev/transients',
    Joep_API_Key: 'AIzaSyC3MGCZa7vlULwyYTVy2MD7Rb86VKBGqyI'
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
