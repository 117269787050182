/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Firestore, collection, getCountFromServer, addDoc, serverTimestamp, QueryDocumentSnapshot, QueryConstraint, orderBy, Timestamp, doc, setDoc, updateDoc } from '@angular/fire/firestore';
import { getDocs, startAfter } from '@angular/fire/firestore';
import { query, where, limit} from '@angular/fire/firestore';

import { Answer, AnswerWithImageAndStats } from './dbhc.model';
import { ThumbnailService } from './thumbnail.service';


@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(private afs: Firestore, private thumbnailService: ThumbnailService) {}

//RETURN DATA BY single FIELD FUNCTIONS
//=======================================================
  async getAnswersForUid(uid: string): Promise<Answer[]> {
    const answersQuery = query(collection(this.afs, 'answers'), where('uid', '==', uid));
    const querySnapshot = await getDocs(answersQuery);
    const answers: Answer[] = [];
    querySnapshot.forEach((doc) => {
      answers.push(doc.data() as Answer);
    });
    return answers;
  }

  getAnswersForTransientId(transientId: string) {
    const q = query(collection(this.afs, 'answers'), where('transientId', '==', transientId));
    return getDocs(q);
  }

//COUNT FUNCTIONS
//=======================================================
  async countAnswersForUid(uid: string){
    const q = query(collection(this.afs, 'answers'), where('uid', '==', uid));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAnswersForUidThisMonth(uid: string){
    const q = query(collection(this.afs, 'answers'), where('uid', '==', uid), where('timestamp', '>=', new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAnswersByTransientId(transientId: string){
    const q = query(collection(this.afs, 'answers'), where('transientId', '==', transientId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAnswerTypeForTransientId(transientId: string, answer: string) {
    const q = query(collection(this.afs, 'answers'), where('transientId', '==', transientId), where('answer', '==', answer));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAllAnswersThisMonth() {
    const q = query(collection(this.afs, 'answers'), where('timestamp', '>=', new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async  countAnswersByAnswerType(answerCategory: string): Promise<number> {
    const q = query(collection(this.afs, 'answers'), where('answer', '==', answerCategory));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async countAnswersByAnswerTypeThisMonth(answerCategory: string) {
    const q = query(collection(this.afs, 'answers'), where('answer', '==', answerCategory), where('timestamp', '>=', new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  async  getAllAnswerTypesCounts() {
    const realCount = await this.countAnswersByAnswerType('real');
    const bogusCount = await this.countAnswersByAnswerType('bogus');
    const dunnoCount = await this.countAnswersByAnswerType( 'dunno');

    return { realCount, bogusCount, dunnoCount };
  }

  async getAllAnswerTypesCountsThisMonth() {
    const realCount = await this.countAnswersByAnswerTypeThisMonth('real');
    const bogusCount = await this.countAnswersByAnswerTypeThisMonth('bogus');
    const dunnoCount = await this.countAnswersByAnswerTypeThisMonth('dunno');

    return { realCount, bogusCount, dunnoCount };
  }

  //new function that uses the answers collection instead of the classification collection to determine if a user has classified a transient already
 async userHasClassifiedTransientId(uid: string, transientId: string): Promise<boolean>{
    const q = query(collection(this.afs, 'answers'), where('uid', '==', uid), where('transientId', '==', transientId));
    const snapshot = await getDocs(q);
     // Check if any documents exist
    if (snapshot.empty) {
      return false;
    }
    return true;
  }

//PAGINATED FUNCTIONS
//=======================================================
  async getAnswersForUidBatch(uid: string, limitRecords: number, lastDocSnapshot?: QueryDocumentSnapshot): Promise<{ answers: Answer[]; lastDocSnapshot: QueryDocumentSnapshot | null }> {
    const currentDate = new Date();
    const startOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const constraints: QueryConstraint[] = [
      where('uid', '==', uid),
      where('timestamp', '>=', Timestamp.fromDate(startOfCurrentMonth)),
      orderBy('transientId', 'desc'),
      limit(limitRecords)
    ];

    if (lastDocSnapshot) {
      constraints.push(startAfter(lastDocSnapshot));
    }

    const q = query(collection(this.afs, 'answers'), ...constraints);
    const querySnapshot = await getDocs(q);

    const answers: Answer[] = [];
    let newLastDocSnapshot: QueryDocumentSnapshot | null = null;

    for (const doc of querySnapshot.docs) {
      const data = doc.data() as AnswerWithImageAndStats;
     // Check if `timestamp` exists and is of the correct type, otherwise set `dateClassified` to 1/1/2023
     if (data.timestamp && data.timestamp.toDate instanceof Function) {
      data.dateClassified = data.timestamp.toDate();
    } else {
      data.dateClassified = new Date('2023-01-01');
    }
      try {
        const imageUrl = await this.thumbnailService.getThumbnail(data.transientId);
        data.thumbnail = imageUrl;
        const classificationCount = await this.countAnswersByTransientId(data.transientId);
        data.classificationCount = classificationCount;
        const realCount = await this.countAnswerTypeForTransientId(data.transientId, 'real');
        data.realCount = realCount;
      } catch (error) {
        // console.error(`No thumbnail for transientId ${data.transientId}: ${error}`);
        data.thumbnail = '';
      }
      answers.push(data);
      newLastDocSnapshot = doc;
    }
    return { answers, lastDocSnapshot: newLastDocSnapshot };
}

//WRITE FUNCTIONS
//=======================================================
  //Old code, doesn't check for existing value
//   async addAnswer(transientId: string, uid: string, answer: string) {
  //     const answerDocRef = await addDoc(collection(this.afs, 'answers'), { transientId, uid, answer, timestamp: serverTimestamp() });
  //     const transientIdNumber = Number(transientId);
  // }

  async addAnswer(transientId: string, uid: string, answer: string) {
    if (answer !== 'real' && answer !== 'bogus' && answer !== 'dunno') {
      throw new Error('Invalid answer type');
    }

    // Create a reference to the 'answers' collection
    const answerQuery = query(
      collection(this.afs, 'answers'),
      where('transientId', '==', transientId),
      where('uid', '==', uid)
    );

    // Check if an answer already exists for this user and transient
    const querySnapshot = await getDocs(answerQuery);

    if (!querySnapshot.empty) {
      // If an existing answer is found, update it
      const existingDocRef = querySnapshot.docs[0].ref;
      await updateDoc(existingDocRef, { answer, timestamp: serverTimestamp() });
      // console.log('Existing answer updated successfully.');
    } else {
      // If no answer is found, add a new one
      await addDoc(collection(this.afs, 'answers'), {
        transientId,
        uid,
        answer,
        timestamp: serverTimestamp()
      });
      // console.log('New answer added successfully.');
    }

  }
}
